import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';

const UpdateButton = ({ handleStatus, status }) => (
  <button
    type="button"
    alt="Update status"
    title="Update status"
    onClick={ handleStatus }
    disabled={ status === 'Done' }
  >
    <AiFillCheckCircle />
  </button>
);

UpdateButton.propTypes = {
  handleStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default UpdateButton;
