import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SaveButton, EditButton, DeleteButton, UpdateButton } from './Buttons';
import { updateTaskAPI, removeTaskAPI } from '../../utils/api/axios';
import {
  StyledTask,
  StyledButtonsContainer,
  StyledTitle,
  StyledDate,
  StyledDescription } from './style';

const Task = ({ id, title, description, updatedAt, status, tasks, setTasks }) => {
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [editable, setEditable] = useState(true);
  const [content, setContent] = useState({ title, description });

  const color = {
    'To do': '#abdeb8',
    'In progress': '#fccc97',
    Done: '#a2c1e8',
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setContent({ ...content, [name]: value });
  };

  const handleTaskUpdate = async () => {
    try {
      const { data } = await updateTaskAPI(id, content, user.token);
      const oldTasks = tasks.filter(({ _id: oldId }) => id !== oldId);
      setTasks([...oldTasks, data.task]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      await removeTaskAPI(id, user.token);
      setTasks(tasks.filter(({ _id: oldId }) => id !== oldId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = async () => {
    try {
      const newStatus = status === 'To do' ? 'In progress' : 'Done';
      const { data } = await updateTaskAPI(id, { status: newStatus }, user.token);
      const oldTasks = tasks.filter(({ _id: oldId }) => id !== oldId);
      setTasks([...oldTasks, data.task]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledTask backgroundColor={ color[status] }>
      <StyledButtonsContainer>
        <EditButton editable={ editable } setEditable={ setEditable } />
        { !editable && <SaveButton handleTaskUpdate={ handleTaskUpdate } />}
        <DeleteButton handleDelete={ handleDelete } />
      </StyledButtonsContainer>
      <StyledTitle>
        <UpdateButton handleStatus={ handleStatus } status={ status } />
        <input
          type="text"
          name="title"
          value={ content.title }
          onChange={ handleChange }
          readOnly={ editable }
          maxLength="40"
        />
      </StyledTitle>
      <StyledDescription>
        <input
          type="text"
          name="description"
          value={ content.description }
          onChange={ handleChange }
          readOnly={ editable }
        />
      </StyledDescription>
      <StyledDate>{moment(updatedAt).format('dddd, DD/MM/YYYY, h:mm a')}</StyledDate>
    </StyledTask>
  );
};

Task.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTasks: PropTypes.func.isRequired,
};

export default Task;
