import styled from 'styled-components';

const StyledForm = styled.form`
  background-color: #eaeeee;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: 300ms;
  width: 100%;

  ${({ showForm }) => {
    let result = '';
    if (showForm) {
      result += `
        height: 50px;
        padding-bottom: 1vh;
        padding-top: 1vh;
      `;
    } else {
      result += `
        height: 0;
      `;
    }

    return result;
  }}

  button {
    background-color: #444949;
    color: #f9ffff;
    font-size: 23px;
    font-weight: bold;
    height: 100%;
    width: 10%;

    &:hover {
      background-color: #232626;
      font-size: 22px;
    }
  }

  input:nth-child(1) {
    font-size: 23px;
    height: 100%;
    width: 50%;
    text-align: center;
    transition: 300ms;
  }

  input:nth-child(2) {
    font-size: 23px;
    height: 100%;
    text-align: center;
    transition: 300ms;
    width: 50%;
  }

  @media only screen and (max-width: 1000px) {
    background-color:  #eaeeee;
    height: 0;
    flex-direction: column;
    font-size: 16px;
    padding: 0px;


    ${({ showForm }) => {
    let result = '';
    if (showForm) {
      result += `
        height: 75px;
        padding-bottom: 1vh;
        padding-top: 1vh;
      `;
    } else {
      result += `
        height: 0;
      `;
    }

    return result;
  }}

    

    input:nth-child(1) {
      font-size: 15px;
      height: 50%;
      width: 100%;
    }

    input:nth-child(2) {
      font-size: 15px;
      height: 50%;
      width: 100%;
    }

    button {
      align-self: center;
      font-size: 13px;
      height: 50%;
      margin-top: 2px;
      width: 20%;

      &:hover {
        background-color: #232626;
        font-size: 13px;
      }
    }
  }
`;

export default StyledForm;
