import styled from 'styled-components';

export const StyledTask = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: 20%;
  margin-bottom: 3%;
  padding: 0;
  width: 100%;

  input {
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    height: 40px;
    margin: 0;
    vertical-align: middle;
    width: 90%;
  }

  input:nth-child(1) {
    background-color: transparent;
    font-size: 20px;
    font-weight: normal;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  @media only screen and (max-width: 1000px) {
    height: 140px;
    
    input {
      font-size: 16px !important;
    }
  }

`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 25%;

  button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 30px;
    height: 35px;
    padding: 1px;
    width: 35px;

    &:hover {
      font-size: 32px;
    }
  }
`;

export const StyledTitle = styled.span`
  display: flex;
  height: 25%;

  button {
    background-color: transparent;
    border: none;
    color: green;
    cursor: pointer;
    font-size: 32px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    width: 10%;
  }

  button :hover {
    font-size: 35px;
  }
  
`;

export const StyledDescription = styled.div`
  height: 25%;
`;

export const StyledDate = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  justify-content: flex-end;
  height: 20%;
  margin-top: 5px;
  padding-right: 20px;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    font-size: 16px !important;
  }
`;
