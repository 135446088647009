import React, { useState, useEffect } from 'react';
import { isExpired } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import TaskBoard from '../../components/TaskBoard';
import FormNewTask from '../../components/FormNewTask';
import Header from '../../components/Header';
import { getAllTasks } from '../../utils/api/axios';
import { StyledBoards, StyledButton, StyledHome } from './style';

const Home = () => {
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || isExpired(user.token)) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchTasks = async () => {
      const data = await getAllTasks(user.token);
      setTasks(data.tasks);
      setLoading(false);
    };
    fetchTasks();
  }, [user]);

  if (loading) return (<div>Loading...</div>);

  return (
    <StyledHome>
      <Header user={ user } />
      <FormNewTask tasks={ tasks } setTasks={ setTasks } showForm={ showForm } />
      <StyledBoards>
        <TaskBoard tasks={ tasks } setTasks={ setTasks } status="To do" />
        <TaskBoard tasks={ tasks } setTasks={ setTasks } status="In progress" />
        <TaskBoard tasks={ tasks } setTasks={ setTasks } status="Done" />
      </StyledBoards>
      <footer>
        <StyledButton
          type="button"
          title="New Task"
          onClick={ () => setShowForm(showForm === false) }
        >
          +
        </StyledButton>
      </footer>
    </StyledHome>
  );
};

export default Home;
