import styled from 'styled-components';

const style = styled.button`
  background-color: #444949;
  border-radius: 10px;
  color: #f9ffff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 3%;
  padding: 5px;
  width: 75%;

  &:disabled {
    background-color: #8a9494;
    color: #f9ffff;
  }
`;

export default style;
