import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './style';

const Input = ({ type, placeholder, value, onChange }) => (
  <StyledInput
    required
    type={ type }
    placeholder={ placeholder }
    value={ value }
    onChange={ onChange }
  />
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Input;
