import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login, Register, Home } from './pages';

function App() {
  return (
    <Routes>
      <Route index element={ <Navigate to="/login" /> } />
      <Route path="/login" element={ <Login /> } />
      <Route path="/register" element={ <Register /> } />
      <Route path="/home" element={ <Home /> } />
    </Routes>
  );
}

export default App;
