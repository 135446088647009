import axios from 'axios';

export const postUserAPI = async (url, body) => {
  const res = await axios({
    method: 'post',
    url,
    data: body,
  });
  const { token } = res.data;
  return token;
};

export const postTaskAPI = async (url, body, token) => {
  const res = await axios({
    method: 'post',
    url,
    data: body,
    headers: { Authorization: token },
  });
  return res;
};

export const getAllTasks = async (token) => {
  const { data } = await axios({
    method: 'get',
    url: 'https://mayfly-backend.herokuapp.com/task',
    headers: { Authorization: token },
  });
  return data;
};

export const updateTaskAPI = async (id, body, token) => {
  const res = await axios({
    method: 'put',
    url: `https://mayfly-backend.herokuapp.com/task/${id}`,
    data: body,
    headers: { Authorization: token },
  });
  return res;
};

export const removeTaskAPI = async (id, token) => {
  const res = await axios({
    method: 'delete',
    url: `https://mayfly-backend.herokuapp.com/task/${id}`,
    headers: { Authorization: token },
  });
  return res;
};
