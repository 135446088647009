import styled from 'styled-components';

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: #eaeeee;
  display: flex;
  min-height: 15%;
  width: 100vw;

  @media only screen and (max-width: 1000px) {
    min-height: 15%;
  }
`;

export const SideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 15vh;
  width: 35vw;

  h3 {
    align-self: center;
    font-size: 30px;
  }

  a {
    align-self: center;
    color: #1c1b1b;
    font-size: 30px;
    text-decoration: none;

    &:hover {
      color: blue;
      font-size: 1.05em;
    }
  }

  img {
    display: block;
    width: 10vw;
  }

  @media only screen and (max-width: 1000px) {
    width: 30vw;

    h3 {
      font-size: 15px;
      text-align: center;
    }

    a {
      font-size: 15px;
    }

    img {
      width: 10vw;
    }
    
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: center;
  width: 40vw;

  h1 {
    font-size: 45px;
    margin-bottom: 10px;
  }

  div {
    font-size: 20px;
  }

  @media only screen and (max-width: 1000px) {
    align-items: center;
    width: 40vw;

    h1 {
      font-size: 25px;
      margin: 10px;
    }

    div {
      font-size: 12px;
    }
  }
`;
