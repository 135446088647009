import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { postTaskAPI } from '../../utils/api/axios';
import StyledForm from './style';

const FormNewTask = ({ tasks, setTasks, showForm }) => {
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setNewTask({ ...newTask, [name]: value });
  };

  return (
    <StyledForm
      showForm={ showForm }
      onSubmit={ async (event) => {
        event.preventDefault();
        try {
          const url = 'https://mayfly-backend.herokuapp.com/task';
          const body = { title: newTask.title, description: newTask.description };
          const { data } = await postTaskAPI(url, body, user.token);
          setTasks([...tasks, data.task]);
          setNewTask({ title: '', description: '' });
        } catch (error) {
          console.log(error);
        }
      } }
    >
      <input
        type="text"
        name="title"
        title="New title"
        placeholder="title"
        value={ newTask.title }
        onChange={ handleChange }
        maxLength="40"
      />
      <input
        type="text"
        name="description"
        title="New description"
        placeholder="description"
        value={ newTask.description }
        onChange={ handleChange }
        maxLength="45"
      />
      <button type="submit">New Task</button>
    </StyledForm>
  );
};

FormNewTask.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTasks: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
};

export default FormNewTask;
