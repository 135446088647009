import styled from 'styled-components';

export const FormContainer = styled.div`
  align-items: center;
  background-color: #f9ffff; 
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  a {
    font-weight: bolder;
    text-decoration: none;
    color: #1c1b1b;

    &:hover {
      color: black;
      font-size: 1.05em;
    }
  }
  
  img {
    display: block;
    height: 50vh;
    margin-bottom: 1%;
    width: 80vw;
  }


  @media only screen and (max-width: 1000px) {
    
    img {
      height: 40vh;
    }

    a {
      font-size: 20px;
    }
  }

`;

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  height: 30vh;
  justify-content: center;
  margin: 0 auto;
  width: 25vw;

  @media only screen and (max-width: 1000px) {
    width: 65vw;

    input {
      font-size: 16px;
      width: 100%;
    }

    button {
      font-size: 16px;
      width: 100%;
    }
  }
`;
