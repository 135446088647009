import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import mayflyleft from '../../images/mayfly-left.svg';
import mayflyright from '../../images/mayfly-right.svg';
import { HeaderContainer, LogoContainer, SideContainer } from './style';

const Header = ({ user }) => {
  const { name } = user;

  const handleLogOut = () => {
    localStorage.clear();
  };

  return (
    <HeaderContainer>
      <SideContainer>
        <img alt="mayfly" src={ mayflyleft } />
        <h3>Current tasks</h3>
      </SideContainer>
      <LogoContainer>
        <br />
        <h1>Mayfly</h1>
        <div>Hello,</div>
        <div>{`${name}`}</div>
        <br />
      </LogoContainer>
      <SideContainer>
        <Link to="/login" onClick={ handleLogOut }><h3>Log out</h3></Link>
        <img alt="mayfly" src={ mayflyright } />
      </SideContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    token: PropTypes.string,
  }).isRequired,
};

export default Header;
