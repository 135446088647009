import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BsSortAlphaDown, BsSortAlphaUp,
  BsSortNumericDown, BsSortNumericUp } from 'react-icons/bs';
import Task from '../Task';
import { StyledTaskBoard, StyledSortButtons } from './style';

const TaskBoard = ({ tasks, setTasks, status }) => {
  const [byName, setByName] = useState('Ascending');
  const [byDate, setByDate] = useState('Descending');
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    const myTasks = tasks.filter((task) => task.status === status);
    myTasks.sort((a, b) => (new Date(a.updatedAt) - new Date(b.updatedAt)));
    setFilteredTasks(myTasks);
  }, [status, tasks]);

  const sortByName = {
    Ascending: () => filteredTasks.sort((a, b) => a.title.localeCompare(b.title)),
    Descending: () => filteredTasks.sort((a, b) => b.title.localeCompare(a.title)),
  };

  const sortByDate = {
    Ascending: () => filteredTasks.sort((a, b) => (
      new Date(a.updatedAt) - new Date(b.updatedAt))),
    Descending: () => filteredTasks.sort((a, b) => (
      new Date(b.updatedAt) - new Date(a.updatedAt))),
  };

  return (
    <StyledTaskBoard>
      <StyledSortButtons>
        <h1>{status}</h1>
        <button
          type="button"
          title={ `Sort by name: ${byName === 'Ascending' ? 'Descending' : 'Ascending'}` }
          onClick={ () => {
            sortByName[byName]();
            setByName(byName === 'Ascending' ? 'Descending' : 'Ascending');
          } }
        >
          {byName === 'Ascending'
            ? <BsSortAlphaUp /> : <BsSortAlphaDown />}
        </button>
        <button
          type="button"
          title={ `Sort by date: ${byDate === 'Ascending' ? 'Most recent' : 'Oldest'}` }
          onClick={ () => {
            sortByDate[byDate]();
            setByDate(byDate === 'Ascending' ? 'Descending' : 'Ascending');
          } }
        >
          {byDate === 'Ascending'
            ? <BsSortNumericUp /> : <BsSortNumericDown />}
        </button>
      </StyledSortButtons>
      <br />
      { filteredTasks
        .map(({ _id, title, description, updatedAt }) => (
          <Task
            key={ _id }
            id={ _id }
            title={ title }
            description={ description }
            updatedAt={ updatedAt }
            status={ status }
            tasks={ tasks }
            setTasks={ setTasks }
          />
        ))}
      <br />
    </StyledTaskBoard>
  );
};

TaskBoard.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTasks: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default TaskBoard;
