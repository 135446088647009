import styled from 'styled-components';

export const StyledTaskBoard = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2%;
  width: 33%;

  @media only screen and (max-width: 1000px) {
    background-color: transparent;
    height: 100%;
    width: 100%;

    h1 {
      font-size: 24px;
    }
  }
`;

export const StyledSortButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 30px;
    height: 35px;
    padding: 1px;
    width: 35px;

    &:hover {
      font-size: 32px;
    }
  }

  h1 {
    justify-content: center;
    margin-right: 5%;
  }
`;

export default StyledTaskBoard;
