import styled from 'styled-components';

export const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  footer {
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    height: 10%;
    position: fixed;
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 130px;
  margin-bottom: 2%;
  margin-right: 2%;
  width: 130px;

  &:hover {
    font-size: 140px;
  }
  
  @media only screen and (max-width: 1000px) {
    font-size: 100px !important;
    height: 100px !important;
    margin-bottom: 1% !important;
    margin-right: 1% !important;
    width: 100px !important;

    &:hover {
      color: darkblue;
      font-size: 100px !important;
    }
  }
`;

export const StyledBoards = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10%;
  width: 100vw;

  @media only screen and (max-width: 1000px) {
    
    flex-direction: column;
    
  }
`;
