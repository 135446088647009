import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import PropTypes from 'prop-types';

const EditButton = ({ editable, setEditable }) => (
  <button
    type="button"
    alt="Edit task"
    title="Edit task"
    onClick={ () => setEditable(editable !== true) }
  >
    <AiFillEdit />
  </button>
);

EditButton.propTypes = {
  editable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
};

export default EditButton;
