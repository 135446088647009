import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import mayflylogo from '../../images/mayfly-logo.svg';
import { postUserAPI } from '../../utils/api/axios';
import verifyRegister from '../../utils/validation/verifyRegister';
import { Button, Input } from '../../components';
import { FormContainer, Form } from '../../global/styles';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const isButtonDisabled = () => (!verifyRegister(email, password, name));
  const [warning, setWarning] = useState('');

  return (
    <FormContainer>
      <img src={ mayflylogo } alt="Mayfly logo" />
      <Form
        onSubmit={ async (event) => {
          event.preventDefault();

          try {
            const url = 'https://mayfly-backend.herokuapp.com/user';
            const token = await postUserAPI(url, { email, password, name });
            const decoded = decodeToken(token);
            const user = {
              id: decoded.id, name: decoded.name, email: decoded.email, token,
            };
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/home');
          } catch (error) {
            setWarning(error.response.data.message);
            const timeout = 5000;
            setTimeout(() => setWarning(''), timeout);
          }
        } }
      >
        <Input
          type="text"
          placeholder="Digite seu nome"
          value={ name }
          onChange={ (event) => setName(event.target.value) }
        />
        <Input
          type="text"
          placeholder="Digite seu email"
          value={ email }
          onChange={ (event) => setEmail(event.target.value) }
        />
        <Input
          type="password"
          placeholder="Digite sua senha"
          value={ password }
          onChange={ (event) => setPassword(event.target.value) }
        />
        <Button type="submit" disabled={ isButtonDisabled() }>Register</Button>
        <Link to="/login">Voltar</Link>
      </Form>
      { warning && <h4>{ warning }</h4> }
    </FormContainer>
  );
};

export default Register;
