import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import PropTypes from 'prop-types';

const DeleteButton = ({ handleDelete }) => (
  <button
    type="button"
    alt="Edit task"
    title="Edit task"
    onClick={ handleDelete }
  >
    <AiFillDelete />
  </button>
);

DeleteButton.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteButton;
