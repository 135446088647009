import React from 'react';
import { AiFillSave } from 'react-icons/ai';
import PropTypes from 'prop-types';

const SaveButton = ({ handleTaskUpdate }) => (
  <button
    type="button"
    alt="Save task"
    title="Save task"
    onClick={ handleTaskUpdate }
  >
    <AiFillSave />
  </button>
);

SaveButton.propTypes = {
  handleTaskUpdate: PropTypes.func.isRequired,
};

export default SaveButton;
