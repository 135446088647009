import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './style';

const Button = ({ type, children, disabled }) => (
  <StyledButton
    type={ type }
    disabled={ disabled }
  >
    { children }
  </StyledButton>
);

Button.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Button;
