import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: #eaeeee;
  font-size: 1em;
  margin-bottom: 3%;
  padding: 5px;
  text-align: center;
  width: 75%;

  &:focus {
    border: 2px solid red;
    outline: none;
  }
`;

export default StyledInput;
