import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { decodeToken, isExpired } from 'react-jwt';
import mayflylogo from '../../images/mayfly-logo.svg';
import { postUserAPI } from '../../utils/api/axios';
import verifyLogin from '../../utils/validation/verifyLogin';
import { Button, Input } from '../../components';
import { FormContainer, Form } from '../../global/styles';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const isButtonDisabled = () => (!verifyLogin(email, password));
  const userStorage = JSON.parse(localStorage.getItem('user'));
  const [warning, setWarning] = useState('');

  useEffect(() => {
    if (userStorage && !isExpired(userStorage.token)) {
      navigate('/home');
    }
  });

  return (
    <FormContainer>
      <img src={ mayflylogo } alt="Mayfly logo" />
      <Form
        onSubmit={ async (event) => {
          event.preventDefault();
          try {
            const url = 'https://mayfly-backend.herokuapp.com/user/login';
            const token = await postUserAPI(url, { email, password });
            const decoded = decodeToken(token);
            const user = {
              id: decoded.id, name: decoded.name, email: decoded.email, token,
            };
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/home');
          } catch (error) {
            setWarning(error.response.data.message);
            const timeout = 5000;
            setTimeout(() => {
              setWarning('');
            }, timeout);
          }
        } }
      >
        <Input
          type="text"
          placeholder="user@email.com"
          value={ email }
          onChange={ (event) => setEmail(event.target.value) }
        />
        <Input
          type="password"
          placeholder="*******"
          value={ password }
          onChange={ (event) => setPassword(event.target.value) }
        />
        <Button type="submit" disabled={ isButtonDisabled() }>LOGIN</Button>
        <Link to="/register">Register</Link>
      </Form>
      { warning && <h4>{ warning }</h4> }
    </FormContainer>
  );
};

export default Login;
